import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { dimensions, breakpoints, widths, media } from '../style/variables'

/**
 * For each breakpoint, define the maximum width of the container in a media query
 */
const containerMaxWidth = (breakpoint: keyof typeof widths) =>
	widths[breakpoint] &&
	css`
		${media.up(breakpoint)} {
			max-width: ${widths[breakpoint]}px;
		}
	`

/**
 * Mirrors bootstrap container class.
 */
export default styled.div`
	width: 100%;
	padding-right: ${dimensions.containerPadding}rem;
	padding-left: ${dimensions.containerPadding}rem;
	margin-right: auto;
	margin-left: auto;

	${breakpoints.map(containerMaxWidth)}
`
