import { css } from '@emotion/core'
import { dimensions, fonts } from './variables'

import form from './form'

/**
 * @todo: move text coloring to css variables.
 *  - it is a mess now.
 *  - buttons inherit color -> use text color variable...
 */
export default css`
	:root {
		--spacing: ${dimensions.containerPadding}rem;
	}

	/**
	 * @see https://github.com/gatsbyjs/gatsby/issues/7310
	 */
	html,
	body,
	#___gatsby,
	div[role='group'][tabindex] {
		height: 100%;
	}

	div[role='group'][tabindex] {
		display: flex;
		flex-direction: column;
	}

	html {
		font-size: ${dimensions.fontSize.regular}rem !important;
		line-height: ${dimensions.lineHeight.regular} !important;
	}

	body {
		width: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		font-family: ${fonts.secondary};
		background-color: rgb(var(--clr-background));
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${fonts.primary};
		font-weight: normal;
		font-style: normal;
		margin-top: 0;
		margin-bottom: 1.414rem;
		line-height: ${dimensions.lineHeight.heading};
		text-rendering: optimizeLegibility;
	}
	h1 {
		margin-top: 0;
		font-size: ${dimensions.headingSizes.h1}rem;
	}
	h2 {
		font-size: ${dimensions.headingSizes.h2}rem;
	}
	h3 {
		font-size: ${dimensions.headingSizes.h3}rem;
	}
	h4,
	h5,
	h6 {
		font-size: ${dimensions.headingSizes.h4}rem;
	}

	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	ul,
	ol,
	dl {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	a {
		color: rgb(var(--clr-accent));
		transition: opacity 0.15s ease-in-out;
		text-decoration: none;

		&:hover {
			opacity: 0.85;
			text-decoration: underline;
		}

		&:focus {
			outline: ${dimensions.focusWidth}px solid rgb(var(--clr-accent));
			outline-offset: 2px;
		}
	}

	${form};
`
