import React from 'react'

type Context = {
	langcode: string
}

const DrupalContext = React.createContext<Context>({
	langcode: 'und' // undefined language
})

export default DrupalContext
