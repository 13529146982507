import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import { DrupalLink } from '../../drupal'
import Icon, { IconType } from './Icon'

type Props = {
	className?: string
	label?: string
	style?: React.CSSProperties
}

interface StaticQueryProps {
	blockContentFooter: {
		langcode: string
		fields: {
			links: DrupalLink[]
		}
	}
}

const StyledSocialMedia = styled.ul`
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;

	.social-media-link {
		color: inherit;
		position: relative;
		top: 2px;
	}
`

const SocialMedia: React.FC<Props> = ({ label, ...props }) => {
	const { blockContentFooter }: StaticQueryProps = useStaticQuery(graphql`
		query {
			blockContentFooter {
				langcode
				fields {
					links {
						uri
						title
					}
				}
			}
		}
	`)

	/**
	 * @todo: most likely should return nothing if blockContentFooter.langcode !=== current langcode
	 */
	return (
		<StyledSocialMedia {...props} className="social-media" aria-label={label}>
			{blockContentFooter.fields.links.map(({ title, uri }) => (
				<li key={title}>
					<a href={uri} className="social-media-link" target="_blank" rel="noopener noreferrer">
						<Icon icon={title as IconType} label={title.charAt(0).toUpperCase() + title.slice(1)} />
					</a>
				</li>
			))}
		</StyledSocialMedia>
	)
}

export default SocialMedia
