import styled from '@emotion/styled'
import css from '@emotion/css'

interface Props {
	width: number
	height: number
	base64?: string
}

const StyledEmbed = styled.div<Props>`
	position: relative;
	padding-top: ${props => (props.height / props.width) * 100}%;

	${props => css`
		background: url(${props.base64});
		background-repeat: no-repeat;
		background-size: cover;
	`}

	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
`

StyledEmbed.defaultProps = {
	width: 16,
	height: 9
}

export default StyledEmbed
