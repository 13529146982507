import React from 'react'
import { DrupalColor } from './drupal'
import { parseToRgb, readableColor } from 'polished'
import { RgbaColor } from 'polished/lib/types/color'

/**
 * Join classNames together.
 * @param  {Array} args classnames
 * @return {String}
 */
export function classNames(...args: (undefined | false | string)[]): string {
	const classes = []

	for (const arg of args) {
		if (!arg) continue

		if (typeof arg === 'string') {
			classes.push(arg)
		}
	}

	return classes.join(' ')
}

/**
 * Convert `gatsby-source-drupal` typenames to css class names.
 *
 * @param typename typename
 */
export function typenameToClass(typename: string) {
	return typename.replace(/_+/g, '-')
}

/**
 * Return true if color is transparent mask color.
 *
 * Drupal color field doesn't allow empty values so white is mapped to
 * transparent.
 *
 * @param color
 */
function isTransparent(color: ReturnType<typeof parseToRgb>) {
	return (color.red === 255 && color.green === 255 && color.blue === 255) || (color as RgbaColor).alpha >= 1.0
}

/**
 * Return CSSProperties with background color.
 *
 * @param color
 */
export function backgroundColorStyle(color?: DrupalColor): React.CSSProperties {
	let parsedColor

	if (!!color && !isTransparent((parsedColor = parseToRgb(color.color)))) {
		return {
			backgroundColor: color.color,
			color: readableColor(color.color),
			'--clr-background': `${parsedColor.red} ${parsedColor.green} ${parsedColor.blue}`
		} as React.CSSProperties
	}

	return {}
}

/**
 * Format type for Schema.org definitions
 */
export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export interface GraphQLGroup<T> {
	fieldValue: string
	nodes: T[]
}

export function pickGroup<T>(fieldValue: string, groups: GraphQLGroup<T>[]): T[] | undefined {
	const group = groups.find(group => group.fieldValue === fieldValue)

	if (group) {
		return group.nodes
	}

	return undefined
}

export const featureTest = {
	localStorage: function checkLocalStorage() {
		const test = 'test'
		try {
			localStorage.setItem(test, test)
			localStorage.removeItem(test)
			return true
		} catch (e) {
			return false
		}
	}
}
