import React, { ReactNode } from 'react'
import Modal from 'react-modal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '500px',
		minWidth: '260px',
		border: 'none',
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.20)',
		borderRadius: '3px'
	},
	overlay: {
		zIndex: 1000
	}
}

type Props = {
	modalIsOpen: boolean
	closeModal?: () => void
	children?: ReactNode
}

const Dialog: React.FC<Props> = props => {
	const { modalIsOpen, closeModal, children } = props
	return (
		<Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
			{children}
		</Modal>
	)
}

export default Dialog
