import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { DrupalMediaImage } from '../../drupal'

interface Props {
	className?: string
	style?: React.CSSProperties
	image?: DrupalMediaImage
}

/**
 * Helper component that is meant to handle the case when background image is not defined.
 */
const WrapBackgroundImage: React.FC<Props> = ({ children, image, ...props }) => {
	if (image) {
		return (
			<BackgroundImage {...props} {...image.relationships.field_media_image.localFile.childImageSharp}>
				{children}
			</BackgroundImage>
		)
	}

	return <div>{children}</div>
}

export default WrapBackgroundImage
