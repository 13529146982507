import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { DrupalColor } from '../drupal'
import { parseToRgb } from 'polished'

interface StaticQueryProps {
	blockContentSiteLogo: {
		field_site_font: string
		field_site_colors: DrupalColor[]
	}
}

export const colorOptions = ['primary', 'secondary', 'accent', 'background', 'warning']

const SiteStyles: React.FC = () => {
	const { blockContentSiteLogo }: StaticQueryProps = useStaticQuery(graphql`
		query SiteStylesData {
			blockContentSiteLogo {
				field_site_font
				field_site_colors {
					color
				}
			}
		}
	`)
	const rootColors = Object.values(colorOptions).map((name, index) => {
		const hexValue = blockContentSiteLogo.field_site_colors[index]['color']
		const color = parseToRgb(hexValue)
		const rootColor = ` --clr-${name}: ${color.red}, ${color.green}, ${color.blue};`
		return rootColor
	})
	const css = Object.values(rootColors).toString().replace(/;\,/g, ';')
	return (
		<style>{`:root {
      ${css}
  }`}</style>
	)
}

export default SiteStyles
