import * as React from 'react'
import styled from '@emotion/styled'

/** Generated with https://icomoon.io/app/ */
import symbols from '../../data/symbol-defs.svg'

const widths = {
	bookmark: {
		width: '0.7142857142857142em'
	},
	cutlery: {
		width: '0.7857142857142857em'
	},
	search: {
		width: '0.9287109375em'
	},
	clock: {
		width: '0.857421875em'
	},
	group: {
		width: '1.0712890625em'
	},
	vimeo: {
		width: '1.0048828125em'
	},
	vk: {
		width: '1.0927734375em'
	},
	instagram: {
		width: '0.857421875em'
	},
	twitter: {
		width: '0.9287109375em'
	},
	facebook: {
		width: '0.587890625em'
	},
	telegram: {},
	youtube: {}
}

export type IconType = keyof typeof widths

interface Props {
	icon: IconType
	label?: string
	className?: string
}

const StyledSvg = styled.svg`
	display: inline-block;
	stroke: currentcolor;
	fill: currentcolor;
	stroke-width: 0;
	height: 1em;
	width: 1em;
`

const Icon: React.FC<Props> = ({ icon, className, label }) => (
	<StyledSvg style={widths[icon]} className={className} aria-label={label}>
		<use xlinkHref={`${symbols}#icon-${icon}`} />
	</StyledSvg>
)

export default Icon
