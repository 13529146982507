import React from 'react'
import styled from '@emotion/styled'
import { Link, graphql, StaticQuery } from 'gatsby'

import { dimensions, heights } from '../style/variables'
import { DrupalBanner, DrupalColor } from '../drupal'
import { backgroundColorStyle } from '../utils'
import { media } from '../style/variables'
import BackgroundImage from './fields/BackgroundImageWrapper'
import Container from './Container'
import Logo from './blocks/Logo'

/**
 * @todo: Consider creating separate type for structure `{ relationships: { field_banner: DrupalBanner } }`
 */
interface StaticQueryProps {
	blockContentSiteLogo: {
		relationships: {
			field_banner: DrupalBanner
		}
	}
}

const StyledContainer = styled(Container)`
	display: none;
	${media.up('md')} {
		padding-top: 5rem;
		padding-bottom: ${dimensions.containerPadding}rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
`
type BannerProps = {
	className?: string
	backgroundColor?: DrupalColor
	image?: DrupalBanner
}

const Banner = (props: BannerProps) => {
	const { className, backgroundColor, image: banner } = props
	return (
		<StaticQuery
			query={graphql`
				{
					blockContentSiteLogo {
						relationships {
							field_banner {
								...DrupalBanner
							}
						}
					}
				}
			`}
			render={({
				blockContentSiteLogo: {
					relationships: { field_banner: defaultBanner }
				}
			}: StaticQueryProps) => (
				<>
					<div className={className} style={backgroundColorStyle(backgroundColor)}>
						<BackgroundImage image={banner || defaultBanner}>
							<StyledContainer className="hero hero-banner" style={{ paddingTop: `${heights.header}rem` }}>
								<Link to="/" title="logo link">
									<Logo />
								</Link>
							</StyledContainer>
						</BackgroundImage>
					</div>
				</>
			)}
		/>
	)
}

export default Banner

export const query = graphql`
	fragment DrupalBanner on media__image {
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`
