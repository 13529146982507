interface Translation {
	[langcode: string]: {
		[key: string]: string
	}
}

/**
 * Source of translated strings for t() function.
 *
 * English strings don't need to be defined here as t() will fall back to
 * the argument string if the translation is missing from this object.
 *
 * This should throw away unused languages when compiled with webpack.
 */
const translations = ({
	fi: {
		'Read more': 'Lue lisää',
		'Open menu': 'Avaa valikko',
		Recipe: 'Resepti ja kuva',
		Portions: 'Annoksia',
		'Follow us on social media': 'Seuraa meitä sosiaalisessa mediassa',
		'The requested URL was not found': 'Voi ei! Sivua ei löytynyt!',
		'To improve the user experience, we use cookies.': 'Parantaaksemme käyttäjäkokemusta käytämme evästeitä.',
		'Accept cookies.': 'Hyväksytkö evästeet?',
		Yes: 'Kyllä',
		No: 'En',
		'Cookie settings': 'Evästeasetukset',
		'Cookie policy': 'Tietoa evästeistä',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'Näitä evästeitä käytetään mainosten esittämiseen ja sisällön uudelleenkohdistamiseen käyttäjän verkkosivustolla tekemien toimien perusteella. Tietoa käytetään mainonnan optimointiin osuvimman markkinoinnin näyttämiseksi. Voit hyväksyä tai hylätä nämä evästeet sivun alareunan bannerista.',
		'Read more about cookie policy': 'Voit lukea lisää evästekäytännöstä klikkaamalla tästä.',
		Close: 'Sulje'
	},
	lt: {
		'Read more': 'Daugiau',
		'Open menu': 'Meniu',
		Recipe: 'Receptas',
		Portions: 'Porcijų kiekis',
		'Follow us on social media': 'Sekite mus socialiniuose tinkluose',
		'The requested URL was not found': 'Puslapis nerastas',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	nb: {
		'Read more': 'Les mer',
		'Open menu': 'Åpne meny',
		Recipe: 'Oppskrift',
		Portions: 'Porsjoner',
		'Follow us on social media': 'Følg oss på sosiale medier',
		'The requested URL was not found': 'Den ønskede URL ble ikke funnet',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	'nb-cc': {
		'Read more': 'Les mer',
		'Open menu': 'Åpne meny',
		Recipe: 'Oppskrift',
		Portions: 'Porsjoner',
		'Follow us on social media': 'Følg oss på sosiale medier',
		'The requested URL was not found': 'Den ønskede URL ble ikke funnet',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	da: {
		'Read more': 'Læs mere',
		'Open menu': 'Åbn menu',
		Recipe: 'Opskrift',
		Portions: 'Portioner',
		'Follow us on social media': 'Følg os på sociale medier',
		'The requested URL was not found': 'Den ønskede URL blev ikke fundet',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	'da-cc': {
		'Read more': 'Læs mere',
		'Open menu': 'Åbn menu',
		Recipe: 'Opskrift',
		Portions: 'Portioner',
		'Follow us on social media': 'Følg os på sociale medier',
		'The requested URL was not found': 'Den ønskede URL blev ikke fundet',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	uk: {
		'Read more': 'Дізнатися більше',
		'Open menu': 'Відкрити меню',
		Recipe: 'Рецепт',
		Portions: 'Порції',
		'Follow us on social media': 'Підписуйтеся на наші соцмережі',
		'The requested URL was not found': 'URL не знайдено',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	ru: {
		'Read more': 'Прочитать больше',
		'Open menu': 'Открыть меню',
		Recipe: 'Рецепт',
		Portions: 'Порции',
		'Follow us on social media': 'Подписывайтесь на наши социальные сети',
		'The requested URL was not found': 'Запрашиваемый URL не был найден',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	},
	pl: {
		'Read more': 'Czytaj więcej',
		'Open menu': 'Otwórz menu',
		Recipe: 'Przepisy',
		Portions: 'Porcje',
		'Follow us on social media': 'Obserwuj nas w social mediach',
		'The requested URL was not found': 'Nie znaleziono żądanego adresu URL',
		'To improve the user experience, we use cookies.':
			'By poprawić Twoje doświadczenia w korzystaniu z naszej strony, używamy ciasteczek.',
		'Accept cookies.': 'Czy akceptujesz pliki cookie?',
		Yes: 'Tak',
		No: 'Nie',
		'Cookie settings': 'Ustawienia plików cookie',
		'Cookie policy': 'Polityka cookie',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'By poprawić Twoje doświadczenia w korzystaniu z naszej strony, używamy ciasteczek.',
		'Read more about cookie policy': 'Przeczytaj więcej o polityce plików cookies.',
		Close: 'Blisko'
	},
	et: {
		'Read more': 'Loe lisaks',
		'Open menu': 'Ava menüü',
		Recipe: 'Retsept',
		Portions: 'Portsjonid',
		'Follow us on social media': 'Jälgi meid sotsiaalmeedias',
		'The requested URL was not found': 'URLi ei leitud',
		'To improve the user experience, we use cookies. Accept cookies ...':
			'To improve the user experience, we use cookies. Accept cookies ...',
		Yes: 'Yes',
		No: 'No',
		'Cookie settings': 'Cookie settings',
		'Cookie policy': 'Cookie policy',
		'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.':
			'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.',
		'Read more about cookie policy': 'Read more about cookie policy',
		Close: 'Close'
	}
} as Translation)[process.env.GATSBY_LANGUAGE as string]

/**
 * Translates a string to the current language
 *
 * @example
 * t('Read more'); // Return 'Lue lisää' for Finnish site.
 *
 * @param str A string containing the English text to translate.
 * @returns translated string or the english string as a fallback.
 */
export const t = (str: string) => {
	return (translations && translations[str]) || str
}
