import React from 'react'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent from 'react-cookie-consent'
import styled from '@emotion/styled'
import { t } from '../i18n'
import Dialog from './Dialog'

const StyledButton = styled.a`
	background-color: transparent;
	border: none;
	font-weight: 400;
	font-size: 16px;
	padding: 5px 10px;
	color: #b4b2b2;
	cursor: pointer;
	&:hover {
		border: none;
		color: #b4b2b2;
		background-color: transparent;
	}
`
const CloseButton = styled.button`
	background-color: rgb(var(--clr-primary));
	color: white;
`

type Props = {
	revalidateCookies: boolean
	setRevalidateCookies: (value: boolean) => void
}

const CookieBanner: React.FC<Props> = ({ revalidateCookies, setRevalidateCookies }) => {
	const [modalIsOpen, setIsOpen] = React.useState(false)
	const location = useLocation()
	const init = () => {
		initializeAndTrack(location)
		setRevalidateCookies(false)
	}
	const polishPolicyUrl = 'https://www.otwarteklatki.pl/polityka-prywatnosci'
	function openModal() {
		if (process.env.GATSBY_LANGUAGE === 'pl') {
			window.open(polishPolicyUrl, '_blank')
		} else {
			setIsOpen(true)
		}
	}
	function closeModal() {
		setIsOpen(false)
	}

	return (
		<>
			<CookieConsent
				enableDeclineButton
				location="bottom"
				buttonText={t('Yes')}
				declineButtonText={t('No')}
				cookieName="gatsby-gdpr-google-tagmanager"
				onAccept={init}
				onDecline={() => setRevalidateCookies(false)}
				buttonStyle={{
					backgroundColor: 'white',
					padding: '10px',
					fontSize: '16px',
					minWidth: 90,
					fontWeight: 500,
					borderRadius: '4px',
					color: 'black'
				}}
				declineButtonStyle={{
					backgroundColor: 'black',
					fontSize: '16px',
					color: ' #b4b2b2',
					borderRadius: '4px',
					minWidth: 50,
					fontWeight: 500
				}}
				visible={revalidateCookies ? 'show' : 'byCookieValue'}
				style={{ backgroundColor: 'black', color: 'white', fontSize: '16px' }}
			>
				{t('To improve the user experience, we use cookies.')}
				<StyledButton onClick={openModal}>{t('Read more about cookie policy')}</StyledButton>
				{t('Accept cookies.')}
			</CookieConsent>
			<Dialog modalIsOpen={modalIsOpen} closeModal={closeModal}>
				<h3>{t('Cookie policy')}</h3>
				<p>
					{t(
						'These cookies are used for presenting advertisement and retargeting content based on the actions that the user makes at the website. Information is used to optimize advertising in order to show the most relevant marketing. You may accept or decline these cookies via cookie policy settings.'
					)}
				</p>
				<CloseButton onClick={closeModal}>{t('Close')}</CloseButton>
			</Dialog>
		</>
	)
}

export default CookieBanner
