import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { classNames, typenameToClass } from '../../utils'
import { DrupalMedia, DrupalMediaImage, DrupalMediaVideo, DrupalMediaGraphics, DrupalMediaLogo } from '../../drupal'
import Embed from './Embed'

interface Props extends React.HTMLProps<HTMLDivElement> {
	source?: DrupalMedia
}

function renderGraphics(media: DrupalMediaGraphics) {
	return (
		<img
			alt={media.field_media_image.alt}
			src={media.relationships.field_media_image.localFile.publicURL}
			draggable={false}
		/>
	)
}

function renderImage(media: DrupalMediaImage) {
	return <Img alt={media.field_media_image.alt} {...media.relationships.field_media_image.localFile.childImageSharp} />
}

function renderIframe(media: DrupalMediaVideo) {
	const thumbnail = media.relationships.thumbnail.localFile.childImageSharp
	return (
		<Embed width={thumbnail.original.width} height={thumbnail.original.height} base64={thumbnail.fixed.base64}>
			<iframe title={media.name} src={media.field_media_oembed_video} frameBorder="0" />
		</Embed>
	)
}

/**
 * Media logo is just like media image but with optional link field.
 */
function renderLogo(media: DrupalMediaLogo) {
	if (media.fields.link.uri) {
		return (
			<a href={media.fields.link.uri} title={media.fields.link.title}>
				<Img alt={media.field_media_image.alt} {...media.relationships.field_media_image.localFile.childImageSharp} />
			</a>
		)
	}

	return <Img alt={media.field_media_image.alt} {...media.relationships.field_media_image.localFile.childImageSharp} />
}

function renderMedia(media: DrupalMedia) {
	switch (media.__typename) {
		case 'media__graphics':
			return renderGraphics(media)
		case 'media__image':
			return renderImage(media)
		case 'media__remote_video':
			return renderIframe(media)
		case 'media__logo':
			return renderLogo(media)
	}
}

/**
 * Drupal media field
 *
 * Remember to query __typename when using this.
 */
const Media: React.FC<Props> = ({ source, className, ...props }) => (
	<div className={classNames(className, 'media', source ? typenameToClass(source.__typename) : 'no-media')} {...props}>
		{source && renderMedia(source)}
	</div>
)

export default Media

/**
 * There is no MediaImage fragment intentionally because image fields have
 * settings and we cannot cover all cases in generic fragments.
 *
 * Media logo width is fixed to 200 pixels.
 */
export const query = graphql`
	fragment MediaGraphics on media__graphics {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					extension
					publicURL
				}
			}
		}
	}

	fragment MediaRemoteVideo on media__remote_video {
		drupal_internal__mid
		name
		field_media_oembed_video
	}

	fragment MediaLogo on media__logo {
		drupal_internal__mid
		field_media_image {
			alt
		}
		fields {
			link {
				uri
				title
			}
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						fixed(width: 200) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}

	fragment MediaImageThumbnail on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						fluid(maxWidth: 570, maxHeight: 375) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}

	fragment MediaImageMedium on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						# maxWidth should be around width of 'md' breakpoint.
						fluid(maxWidth: 720, maxHeight: 720) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`
